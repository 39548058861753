import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import iconExtractImg from "../../images/icon_extract.svg"
import iconLoadImg from "../../images/icon_load.svg"
import iconTransformImg from "../../images/icon_transform.svg"
import iconScheduleImg from "../../images/icon_scheduled.svg"
import iconQualityImg from "../../images/icon_quality.svg"
import iconAuditsImg from "../../images/icon_auditorias.svg"
import iconMetadataImg from "../../images/icon_metadata.svg"
import iconNotificationImg from "../../images/icon_notification.svg"
import iconLogsImg from "../../images/icon_logs.svg"
import iconIntegrationImg from "../../images/icon_integracion.svg"
import iconUsersImg from "../../images/icon_usuarios.svg"
import iconReportingImg from "../../images/icon_reporting.svg"

const Feature = ({ img, title, description }) => {
  return (
    <Col sm={12} md={4} className="my-3" data-sal="slide-up">
      <img src={img} height="80" />
      <h4>{title}</h4>
      <div className="text-primary">{description}</div>
    </Col>
  )
}

const Features = () => {
  return (
    <Container fluid className="iconos">
      <Container>
        <Row>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconExtractImg} height="80" />
            <h4>Data extraction</h4>
            <div className="text-primary">
            We export data from different sources (postgresql, mysql, sqlserver...) to csv or compressed csv files.
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconLoadImg} height="80" />
            <h4>Data upload</h4>
            <div className="text-primary">
            We can copy data between a source database and a destination database or copy data from files (csv, compressed csv) to a destination database.
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconTransformImg} height="80" />
            <h4>Data transformation</h4>
            <div className="text-primary">
            All our transformations are done with native SQL-PL/SQL.
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconScheduleImg} height="80" />
            <h4>Sheduling</h4>
            <div className="text-primary">
            We can schedule the execution of processes or a group of processes in a coordinated way.
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconQualityImg} height="80" />
            <h4>Quality</h4>
            <div className="text-primary">
            We can define controls to verify if the processed data are correct (volumetry,metric, etc).
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconAuditsImg} height="80" />
            <h4>Monitoring</h4>
            <div className="text-primary">
            We record the result of the execution of any process (number of records, dates, times, etc).
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconMetadataImg} height="80" />
            <h4>Metadata</h4>
            <div className="text-primary">
            Our metadata provides you with additional information about your processes (dependencies, tables, data sources used, etc).
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconNotificationImg} height="80" />
            <h4>Notifications</h4>
            <div className="text-primary">
            Notifications can be defined for certain events (completion of a process, unfulfilled quality rules, etc).
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconLogsImg} height="80" />
            <h4>Logs</h4>
            <div className="text-primary">
            We generate detailed and summarized log files.
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconIntegrationImg} height="80" />
            <h4>Continuous integration</h4>
            <div className="text-primary">
            Production and development environments. <b>(Coming soon...)</b>
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconUsersImg} height="80" />
            <h4>Users</h4>
            <div className="text-primary">
            You can add new users and manage permissions at the level of processes and connections.
            </div>
          </Col>
          <Col sm={12} md={4} className="my-3" data-sal="slide-up">
            <img src={iconReportingImg} height="80" />
            <h4>Reporting:</h4>
            <div className="text-primary">
              <b>(Coming soon...)</b>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Features
