import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"

const LoveSql = () => {
  return (
    <Container fluid className="cta">
      <Container>
        <div className="heart"></div>

        <h1 data-sal="slide-up">Love SQL</h1>
        <h4 data-sal="slide-up" data-sal-delay="200">
        Open Datastage simplifies all processes so that you only have to use SQL, the standard that every data professional should know.
        </h4>
        <div data-sal="slide-up" data-sal-delay="400">
        Most of the tools are based on visual components that are complex to use and oriented towards more technical profiles.
        </div>
        <Link
          className="btn btn-blanco mt-5"
          data-sal="slide-up"
          data-sal-delay="600"
          to="/signup"
        >
          START FREE TRIAL
        </Link>
      </Container>
    </Container>
  )
}

export default LoveSql
