import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import hombrepizarraImg from "../../images/hombrepizarra.svg"
import logoImg from "../../images/logo.svg"
import teamImg from "../../images/team.svg"
import pcImg from "../../images/pc.svg"

const Home = () => {
  return (
    <Container fluid className="bg-light infografia">
      <Container>
        <Row>
          <Col sm={12} lg={3} className="text-left">
            <h3 className="mb-4 text-center" data-sal="slide-up">
              Data Source
            </h3>
            <div className="imagenpizarra" data-sal="slide-up">
              <img src={hombrepizarraImg} width="100%" />
            </div>

            <div className="text-center d-none d-lg-block flechaprimera">
              <div
                className="flechitas"
                data-sal="slide-left"
                data-sal-delay="400"
              >
                <i className="las la-arrow-right text-secondary"></i>
              </div>
            </div>

            <div className="text-center d-block d-lg-none mb-3 mt-3">
              <div className="flechitas wow" data-sal="slide-up">
                <i className="las la-arrow-down text-secondary"></i>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <Row className="d-flex align-items-center h100">
              <Col md={12} lg={9} data-sal="slide-up" data-sal-delay="400">
                <h3 className="mb-0 text-center">
                  <img src={logoImg} width="300" />
                </h3>

                <div className="circuito">
                  <span className="circulito pinkBg circulito-central">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">
                      70% of time
                      <br />
                      spent here
                    </span>
                  </span>

                  <span className="circulito pinkBg circulito1">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">ETL</span>
                  </span>

                  <span className="circulito pinkBg circulito2">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Log</span>
                  </span>

                  <span className="circulito pinkBg circulito3">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Metadata</span>
                  </span>

                  <span className="circulito pinkBg circulito4">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Audit</span>
                  </span>

                  <span className="circulito pinkBg circulito5">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Users</span>
                  </span>

                  <span className="circulito pinkBg circulito6">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Reporting</span>
                  </span>

                  <span className="circulito pinkBg circulito7">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Notification</span>
                  </span>

                  <span className="circulito pinkBg circulito8">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Quality</span>
                  </span>

                  <span className="circulito pinkBg circulito9">
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="ripple pinkBg"></span>
                    <span className="textocirculito">Tests</span>
                  </span>
                </div>

                <div className="flechitas text-center">
                  <i className="las la-arrow-down text-secondary"></i>
                  <i className="las la-arrow-up text-secondary"></i>
                </div>

                <div>
                  <h3 className="text-center my-3">Team</h3>
                  <div className="text-center">
                    <img src={teamImg} width="80%" className="mx-auto" />
                  </div>
                </div>
              </Col>

              <Col
                md={12}
                lg={3}
                className="text-center mb-5 mb-lg-0 d-none d-lg-block flechassegundas"
                data-sal="slide-left"
                data-sal-delay="600"
              >
                <div className="d-inline-block text-secondary font-weight-bold font-12 puntitostitle">
                  <i className="las la-cloud"></i>
                  <br />
                  On-Cloud
                </div>

                <div className="d-inline-block flechitas mb-2">
                  <i className="las la-arrow-right text-secondary"></i>
                </div>

                <div className="d-inline-block text-secondary font-weight-bold font-12 mb-2 puntitostitle">
                  <i className="las la-desktop"></i>
                  <br />
                  On-Premise
                </div>
              </Col>
              <Col
                md={12}
                lg={3}
                className="mt-5 text-center mb-5 mb-lg-0 d-block d-lg-none"
                data-sal="slide-up"
              >
                <div className="d-inline-block text-secondary font-weight-bold font-12 puntitostitle">
                  <i className="las la-cloud"></i>
                  <br />
                  On-Cloud
                </div>
                <div className="d-inline-block flechitas mb-2">
                  <i className="las la-arrow-down text-secondary"></i>
                </div>
                <div className="d-inline-block text-secondary font-weight-bold font-12 mb-2 puntitostitle">
                  <i className="las la-desktop"></i>
                  <br />
                  On-Premise
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={3} className="text-center">
            <h3
              className="mb-4 text-center"
              data-sal="slide-up"
              data-sal-delay="800"
            >
              DWH
            </h3>
            <h5
              className="circulos-dwh"
              data-sal="flip-left"
              data-sal-delay="800"
            >
              <i className="las la-check"></i> Stage
            </h5>
            <h5
              className="circulos-dwh"
              data-sal="flip-left"
              data-sal-delay="800"
            >
              <i className="las la-check"></i> Dim
            </h5>
            <br />
            <h5
              className="circulos-dwh"
              data-sal="flip-left"
              data-sal-delay="800"
            >
              <i className="las la-check"></i> Fact
            </h5>
            <h5
              className="circulos-dwh"
              data-sal="flip-left"
              data-sal-delay="800"
            >
              <i className="las la-check"></i> Olap
            </h5>

            <div
              className="flechitas mb-2"
              data-sal="flip-left"
              data-sal-delay="800"
            >
              <i className="las la-arrow-down text-secondary"></i>
            </div>

            <img
              src={pcImg}
              className="mt-2"
              data-sal="slide-up"
              data-sal-delay="800"
              data-wow-delay="0.8s"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Home
